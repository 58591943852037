import React from "react";
import { AiOutlineSend } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import { BsFillEmojiLaughingFill } from "react-icons/bs";
import { IoPersonCircleSharp } from "react-icons/io5";

import { viewTemplate } from "./utils/viewTemplate";
import { useWhatsappContext } from "../contexts/WhatsappContextProvider";

const WhatsappUi = ({ currTemplate }) => {
  const { wbProfileInfo, wbPhoneNumberStatus } = useWhatsappContext();

  return (
    <div className="bg-gradient-to-b from-wabmGreen to-wabmBlue w-full h-full pb-16 md:pb-0 flex flex-col justify-between gap-5">
      <div className="space-y-2">
        <div className="bg-whatsAppColor1 p-2 flex items-center gap-2 text-white">
          <BiArrowBack />
          {wbProfileInfo?.profilePic ? (
            <img
              src={wbProfileInfo?.profilePic}
              alt="WhatsApp Profile Pic"
              className="w-8 h-8 rounded-full"
            />
          ) : (
            <IoPersonCircleSharp className="w-8 h-8" />
          )}
          <h3 className="text-lg">
            {wbPhoneNumberStatus?.verified_name
              ? wbPhoneNumberStatus?.verified_name
              : "WaBM"}
          </h3>
        </div>
        <h4 className="bg-slate-100 w-fit mt-2 mx-auto p-2 text-sm text-center text-slate-700 rounded">
          Today
        </h4>
        {currTemplate?.name && (
          <div className="bg-slate-200 bg-opacity-60 mx-auto py-2 text-center text-sm">
            <p className="font-semibold">{currTemplate?.name}</p>
            <span className="inline-flex gap-2">
              [<p>{currTemplate?.category}</p> | <p>{currTemplate?.language}</p>
              ]
            </span>
            <p className="text-xs">{currTemplate?.status}</p>
          </div>
        )}
      </div>

      <div className="mb-2 px-2 space-y-2">
        <div className="bg-white w-2/3 md:w-full lg:w-4/5 2xl:w-2/3 rounded-md">
          {currTemplate?.components?.map((element) =>
            viewTemplate(element, true)
          )}
        </div>
        <div className="w-full inline-flex gap-2">
          <span className="bg-white w-full px-2 flex justify-between items-center gap-2 rounded-lg lg:rounded-full">
            <BsFillEmojiLaughingFill size={20} />
          </span>
          <AiOutlineSend
            size={32}
            className="bg-whatsAppColor1 p-2 text-white cursor-pointer rounded-full"
          />
        </div>
      </div>
    </div>
  );
};

export default WhatsappUi;
