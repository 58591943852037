import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Input,
  Button,
  Option,
  Select,
} from "@material-tailwind/react";

import { AppNavbar, Footer, SeoTags } from "../components";
import { useUserContext } from "../contexts/UserContextProvider";
import { businessTypes } from "../data/businessTypes";
import {
  emailValidationCheck,
  phoneNumberValidationCheck,
} from "../components/utils/inputValidations";
import {
  phoneNumberSanitation,
  inputNameSanitation,
  inputMessageSanitation,
} from "../components/utils/inputSanitation";
import allCountryCodes from "../data/countryAndCodes.json";
import wabmSignup from "../images/props/wabm-signup.png";
import firework from "../images/props/firework.png";

const Signup = () => {
  const {
    isLoggedIn,
    loginCheck,
    currentUserInfo,
    apiCallRegister,
    registrationStatus,
  } = useUserContext();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("91");
  const [phoneNo, setPhoneNo] = useState("");

  const resetStates = () => {
    setCountryCode("91");
    setFirstName("");
    setLastName("");
    setBusinessType("");
    setBusinessName("");
    setEmail("");
    setPhoneNo("");
  };

  const registerUserOnSubmit = (event) => {
    event.preventDefault();
    if (emailValidationCheck(email) && phoneNumberValidationCheck(phoneNo)) {
      apiCallRegister(
        firstName,
        lastName,
        businessName,
        businessType,
        email,
        countryCode + "" + phoneNo
      );

      resetStates();
    } else {
      toast.warn("Please check the entered values");
    }
  };

  useEffect(() => {
    loginCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <div className="bg-[url(../src/images/props/y-so-serious-white.png)] bg-repeat min-h-screen flex flex-col justify-between overflow-x-hidden">
      <SeoTags
        title="Signup ‣ WaBM | Best WhatsApp business marketing tool in Delhi"
        description="Create and manage WhatsApp marketing campaigns, Track customer engagement, Broadcast messages, Schedule messages and more!"
      />

      <AppNavbar />
      <main className="container mx-auto px-5 pt-16 pb-10">
        <div className="w-full grid grid-cols-1 md:grid-cols-5 gap-5">
          <div className="md:col-span-3 self-center space-y-2 text-center">
            <h2 className="text-xl text-wabmBlue font-bold">
              Easy Onboarding. Quick Setup.
            </h2>
            <p>
              Just fill out the form to get started with WaBM and get a taste of
              modern marketing magic.
            </p>
            <img
              src={wabmSignup}
              className="lg:w-3/5 mx-auto"
              alt="WaBM Sign Up"
            />
          </div>

          <Card className="w-full self-center md:col-span-2">
            <CardHeader
              variant="gradient"
              color="blue"
              className="p-4 grid h-28 place-items-center"
            >
              <Typography variant="h3" color="white">
                Sign Up
              </Typography>
            </CardHeader>
            <CardBody className="flex flex-col gap-4">
              {isLoggedIn ? (
                <>
                  <Typography
                    variant="h4"
                    color="blue"
                    className="mx-auto inline-flex gap-1"
                  >
                    <p>Hi</p>
                    <p className="uppercase font-semibold">
                      {currentUserInfo?.agentName}!
                    </p>
                  </Typography>
                  <p className="text-center">You are already logged in!</p>

                  {currentUserInfo?.mandatoryUserDetailsExist &&
                  currentUserInfo?.mandatoryWabApiCreds ? (
                    <NavLink to="/dashboard">
                      <Button variant="gradient" color="blue" fullWidth>
                        Goto Dashboard ⇒
                      </Button>
                    </NavLink>
                  ) : (
                    <NavLink to="/setup">
                      <Button variant="gradient" color="blue" fullWidth>
                        Let's Get Started
                      </Button>
                    </NavLink>
                  )}
                </>
              ) : registrationStatus ? (
                <div className="w-full py-20 flex flex-col gap-5">
                  <img
                    src={firework}
                    alt="You are registered with WaBM"
                    className="w-1/2 mx-auto"
                  />
                  <h2 className="font-bold text-2xl">
                    Thanks for Registering with WaBM!
                  </h2>
                  <h3 className="font-semibold">
                    You have taken your initial steps to boost your business.
                  </h3>
                  <p>
                    Now sit back, relax and our onboarding team will contact you
                    after reviewing your information within 48 hours.
                  </p>
                </div>
              ) : (
                <form
                  onSubmit={registerUserOnSubmit}
                  className="w-full flex flex-col gap-5"
                >
                  <p>
                    Unlock the <strong>Power of Our WhatsApp Business</strong>{" "}
                    now. Reach Millions, Launch Campaigns, and Automate Chats
                    with Ease!
                  </p>
                  <div className="mt-2 flex flex-col gap-4">
                    <Input
                      label="First Name"
                      variant="standard"
                      color="blue"
                      labelProps={{
                        className: "text-slate-800",
                      }}
                      required
                      placeholder="Please type your First Name"
                      value={firstName}
                      onChange={(e) =>
                        setFirstName(inputNameSanitation(e.target.value))
                      }
                      className="my-input-box-1"
                    />
                    <Input
                      label="Last Name"
                      variant="standard"
                      color="blue"
                      labelProps={{
                        className: "text-slate-800",
                      }}
                      required
                      placeholder="Please type your Last Name"
                      value={lastName}
                      onChange={(e) =>
                        setLastName(inputNameSanitation(e.target.value))
                      }
                      className="my-input-box-1"
                    />
                    <Select
                      label="Business Type"
                      variant="standard"
                      color="blue"
                      required
                      value={businessType}
                      onChange={(e) => setBusinessType(e)}
                      className="my-input-box-1"
                    >
                      {businessTypes.map((business, index) => (
                        <Option
                          key={index}
                          value={business}
                          className={`hover:bg-blue-300 focus:bg-blue-300 ${
                            businessType === business ? "bg-wabmGreen" : ""
                          }`}
                        >
                          {business}
                        </Option>
                      ))}
                    </Select>
                    <Input
                      label="Business Name"
                      variant="standard"
                      color="blue"
                      labelProps={{
                        className: "text-slate-800",
                      }}
                      placeholder="Please type your Business/Company's Name"
                      value={businessName}
                      onChange={(e) =>
                        setBusinessName(inputMessageSanitation(e.target.value))
                      }
                      className="my-input-box-1"
                    />
                    <div className="flex flex-wrap xl:flex-nowrap items-center gap-2">
                      <Select
                        label="Country Code"
                        variant="standard"
                        color="blue"
                        value={countryCode}
                        required
                        onChange={(e) => setCountryCode(e)}
                        className="my-input-box-1"
                      >
                        {allCountryCodes.map((country) => (
                          <Option
                            key={country.code}
                            value={country.dial_code}
                            className={`hover:bg-blue-300 focus:bg-blue-300 ${
                              countryCode === country.dial_code
                                ? "bg-wabmGreen"
                                : ""
                            }`}
                          >
                            {country.code + " +" + country.dial_code}
                          </Option>
                        ))}
                      </Select>
                      <Input
                        label="Enter Phone Number"
                        variant="standard"
                        color="blue"
                        labelProps={{
                          className: "text-slate-800",
                        }}
                        required
                        value={phoneNo}
                        onChange={(e) =>
                          setPhoneNo(phoneNumberSanitation(e.target.value))
                        }
                        className="my-input-box-1"
                      />
                    </div>
                    <Input
                      label="Email ID"
                      variant="standard"
                      color="blue"
                      labelProps={{
                        className: "text-slate-800",
                      }}
                      required
                      placeholder="Please type your Email ID"
                      value={email}
                      onChange={(e) => setEmail(e.target.value.trim())}
                      className="my-input-box-1"
                    />
                  </div>
                  <Button
                    variant="gradient"
                    color="blue"
                    type="submit"
                    fullWidth
                  >
                    Sign Up
                  </Button>
                </form>
              )}
            </CardBody>
          </Card>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Signup;
