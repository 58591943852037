import React, { useState, createContext, useContext } from "react";
import { toast } from "react-toastify";

import { appSettings } from "../config/appConfig";

const WhatsappContext = createContext();

export const WhatsappContextProvider = ({ children }) => {
  const baseUrl = appSettings.serverBaseUrl;

  const [isLoading, setIsLoading] = useState(false);
  const [wabInfo, setWabInfo] = useState({});

  const [wbProfileInfo, setWbProfileInfo] = useState({});
  const [wbPhoneNumberStatus, setWbPhoneNumberStatus] = useState({});
  const [wabAnalytics, setWabAnalytics] = useState([]);
  const [conversationInsights, setConversationInsights] = useState([]);
  const [allTemplates, setAllTemplates] = useState([]);
  const [templateAnalytics, setTemplateAnalytics] = useState([]);
  const [templateReload, setTemplateReload] = useState(true);

  const [toShowScheduleSettings, setToShowScheduleSettings] = useState(false);
  const [scheduledCampaignsData, setScheduledCampaignsData] = useState([]);
  const [selectedJobIdToView, setSelectedJobIdToView] = useState(0);
  const [selectedJobToView, setSelectedJobToView] = useState({});

  const [isNeedResetScheduleDate, setNeedResetScheduleDate] = useState(1);
  const [isOpenReschedule, setOpenReschedule] = useState(false);
  const [resumableMediaId, setResumableMediaId] = useState("");

  const apiFetchWabApiCreds = () => {
    fetch(`${baseUrl}/api/fetch-wab-api-creds`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 500) toast.error("No API keys found");
        else {
          if (response.message) {
            toast.info(response.message);
          }
          setWabInfo(response);
        }
      })
      .catch((err) => {
        toast.error("Failed to fetch WhatsApp Creds");
      });
  };

  const getWbProfileInfo = () => {
    setIsLoading(true);
    fetch(`${baseUrl}/wab/get-wab-profile-info`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Error) {
          toast.error(response.Error);
        } else {
          setWbProfileInfo(response);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Failed to fetch Profile Data");
      });
  };

  const getWbPhoneNumberStatus = () => {
    setIsLoading(true);
    fetch(`${baseUrl}/wab/get-phone-number-status`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Error) {
          toast.error(response.Error);
        } else {
          setWbPhoneNumberStatus(response);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Failed to fetch Phone Number Status");
      });
  };

  const updateWbProfileInfo = (bodyData) => {
    setIsLoading(true);
    fetch(`${baseUrl}/wab/update-wab-profile-info`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyData),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Error) {
          toast.error(response.Error);
        } else {
          toast.success(response.message);
          getWbProfileInfo();
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Failed to update Profile Data");
      });
  };

  const getWabAnalytics = (granularity, startTimestamp, endTimestamp) => {
    setIsLoading(true);
    fetch(`${baseUrl}/wab/wab-analytics`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        granularity,
        startTimestamp,
        endTimestamp,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Error) {
          toast.error(response.Error);
        } else {
          setWabAnalytics(response);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Failed to fetch WhatsApp Insights");
      });
  };

  const getConversationInsights = (
    granularity,
    startTimestamp,
    endTimestamp
  ) => {
    setIsLoading(true);
    fetch(`${baseUrl}/wab/conversation-insights`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        granularity,
        startTimestamp,
        endTimestamp,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Error) {
          toast.error(response.Error);
        } else {
          setConversationInsights(response);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Failed to fetch WhatsApp Insights");
      });
  };

  const getTemplates = () => {
    setIsLoading(true);
    fetch(`${baseUrl}/wab/get-wab-templates`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Error) {
          toast.error(response.Error);
        } else {
          setAllTemplates(response);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Failed to make templates fetch request");
      });
  };

  const apiCallCreateTemplate = async (formdata) => {
    try {
      const createTemplateResponse = await fetch(
        `${baseUrl}/wab/create-wab-template`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formdata),
        }
      );

      const res = await createTemplateResponse.json();

      if (res?.Error) {
        toast.error(res.Error);
        return false;
      } else {
        toast.success(res?.message);
        return true;
      }
    } catch (error) {
      toast.error("Failed to create new template request");
      return false;
    }
  };

  const apiCallDeleteTemplate = (templateToBeDeleted) => {
    setTemplateReload(false);
    fetch(`${baseUrl}/wab/delete-wab-template`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: templateToBeDeleted }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Error) {
          toast.error(response.Error);
        } else {
          toast.success("Template deleted successfully");
          setTemplateReload(true);
        }
      })
      .catch((err) => {
        // console.log("apiCallDeleteTemplate err", err);
        toast.error(err || "Failed to make template delete request");
      });
  };

  const allTemplateAnalytics = (
    granularity,
    startTimestamp,
    endTimestamp,
    templateIds
  ) => {
    setIsLoading(true);
    fetch(`${baseUrl}/wab/all-template-analytics`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        granularity,
        startTimestamp,
        endTimestamp,
        templateIds,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        // console.log("allTemplateAnalytics response", response);
        if (response?.Error) {
          toast.error(response.Error);
        } else {
          setTemplateAnalytics(response);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Failed to fetch Template Analytics");
        setIsLoading(false);
      });
  };

  const sendDirectMessage = (phoneNumber, messageContent) => {
    setIsLoading(true);
    fetch(`${baseUrl}/wab/send-wab-direct-message`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phoneNumber,
        messageType: "text",
        messageContent,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Error) {
          toast.error(response.Error);
        } else {
          toast.success("Message Sent");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Failed to send DM");
      });
  };

  const sendInteractiveMessage = (
    phoneNumber,
    headerText,
    bodyText,
    footerText,
    buttonText,
    buttonUrl
  ) => {
    setIsLoading(true);
    fetch(`${baseUrl}/wab/send-interactive-message`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phoneNumber,
        headerText,
        bodyText,
        footerText,
        buttonText,
        buttonUrl,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.Error) {
          toast.error(response.Error);
        } else {
          toast.success("Interactive Message Sent");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Failed to send Interactive Message");
      });
  };

  const sendMessageViaTemplate = (
    phoneNumber,
    selectedTemplate,
    parametersToBeSent,
    variableFields,
    countryCode
  ) => {
    setIsLoading(true);
    fetch(`${baseUrl}/wab/send-wab-message-template`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phoneNumber,
        selectedTemplate,
        parametersToBeSent,
        variableFields,
        countryCode,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.Error) {
          toast.error(response.Error);
        } else if (response?.message) {
          toast.success(response.message);
        } else {
          toast.info("Sending message template failed!");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Failed to send Template message");
      });
  };

  const startGroupCampaign = (
    selectedContactGroup,
    selectedTemplate,
    parametersToBeSent,
    selectedField
  ) => {
    setIsLoading(true);
    fetch(`${baseUrl}/wab/start-group-campaign`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        selectedContactGroup,
        selectedTemplate,
        parametersToBeSent,
        selectedField,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.Error) {
          toast.error(response.Error);
        } else if (response?.message) {
          toast.success(response.message);
        } else {
          toast.info("Campaign initiated");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Failed to start campaign");
      });
  };

  const scheduleGroupCampaign = (
    scheduleName,
    scheduleDescription,
    scheduleType,
    scheduledTime,
    selectedContactGroup,
    selectedTemplate,
    parametersToBeSent,
    selectedField,
    contactType
  ) => {
    setIsLoading(true);
    fetch(`${baseUrl}/wab/schedule-group-campaign`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        scheduleName,
        scheduleDescription,
        scheduleType,
        scheduledTime,
        selectedContactGroup,
        selectedTemplate,
        parametersToBeSent,
        selectedField,
        contactType,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Error) {
          toast.error(response.Error);
        } else if (response.message) {
          toast.success(response.message);
        } else {
          toast.warn("Status unknown");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Failed to scheduled campaign");
      });
  };

  const rescheduleGroupCampaign = (jobId, scheduledTime) => {
    setIsLoading(true);
    fetch(`${baseUrl}/wab/reschedule-group-campaign`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        jobId: jobId,
        scheduleDateTime: scheduledTime,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Error) {
          toast.error(response.Error);
        } else if (response.message) {
          setOpenReschedule(false);
          showScheduledCampaigns("active");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Failed to scheduled campaign");
      });
  };

  const showScheduledCampaigns = (reqStatus) => {
    setIsLoading(true);
    fetch(`${baseUrl}/wab/show-scheduled-campaigns?reqStatus=${reqStatus}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Error) {
          toast.error(response?.Error);
        } else if (response.message) {
          toast.success(response?.message);
        } else if (response?.data) {
          setScheduledCampaignsData(response.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Failed to load scheduled campaigns");
      });
  };

  const toggleScheduledCampaign = (selectedScheduleId, toggleStatus) => {
    setIsLoading(true);
    fetch(`${baseUrl}/wab/toggle-scheduled-campaign`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ selectedScheduleId, toggleStatus }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Error) {
          toast.error(response.Error);
        } else if (response.message) {
          setNeedResetScheduleDate(response.data);
          if (response.data === 1) {
            toast.success(response.message);
          }
          showScheduledCampaigns("active");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Failed to toggle scheduled campaign");
      });
  };

  const sendMediaMessage = async (mediaFormData) => {
    try {
      const sendMediaMessageResponse = await fetch(
        `${baseUrl}/wab/send-media-message`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(mediaFormData),
        }
      );

      const res = await sendMediaMessageResponse.json();

      if (res?.Error) {
        toast.error(res.Error);
      } else {
        toast.success("Message Sent Successfully");
      }
    } catch (err) {
      setIsLoading(false);
      toast.error("Failed to send media message");
    }
  };

  const startResumableUpload = async (mediaFormData) => {
    setIsLoading(true);
    fetch(`${baseUrl}/wab/resumable-upload`, {
      method: "POST",
      credentials: "include",
      body: mediaFormData,
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Error) {
          toast.error(response.Error);
        } else {
          setResumableMediaId(response?.id.h);
          toast.success("Media Uploaded Successfully");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Failed to upload media message");
      });
  };

  const apiRegisterPhoneNo = async (pin) => {
    try {
      setIsLoading(true);
      const response = await fetch(`${baseUrl}/wab/register-phone`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pin: pin,
        }),
      });

      const data = await response.json();
      if (data?.Error) {
        toast.error(data.Error);
      } else {
        toast.success(data?.message);
      }
    } catch (err) {
      toast.error("Failed to register phone number", err?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const apiRegisterWebhook = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${baseUrl}/wab/register-webhook`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      if (data?.Error) {
        toast.error(data.Error);
      } else {
        toast.success(data?.message);
      }
    } catch (err) {
      toast.error("Failed to register webhook", err?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <WhatsappContext.Provider
      value={{
        getWbProfileInfo,
        getWbPhoneNumberStatus,
        updateWbProfileInfo,
        apiFetchWabApiCreds,
        getTemplates,
        getWabAnalytics,
        getConversationInsights,
        apiCallCreateTemplate,
        apiCallDeleteTemplate,
        allTemplateAnalytics,
        sendDirectMessage,
        sendMessageViaTemplate,
        sendInteractiveMessage,
        startGroupCampaign,
        scheduleGroupCampaign,
        showScheduledCampaigns,
        toggleScheduledCampaign,
        sendMediaMessage,
        startResumableUpload,
        allTemplates,
        templateAnalytics,
        wbProfileInfo,
        wbPhoneNumberStatus,
        wabAnalytics,
        conversationInsights,
        wabInfo,
        isLoading,
        templateReload,
        isNeedResetScheduleDate,
        toShowScheduleSettings,
        scheduledCampaignsData,
        selectedJobToView,
        selectedJobIdToView,
        isOpenReschedule,
        setIsLoading,
        setTemplateReload,
        setToShowScheduleSettings,
        setSelectedJobToView,
        setSelectedJobIdToView,
        setNeedResetScheduleDate,
        rescheduleGroupCampaign,
        setOpenReschedule,
        resumableMediaId,
        setResumableMediaId,
        apiRegisterPhoneNo,
        apiRegisterWebhook,
      }}
    >
      {children}
    </WhatsappContext.Provider>
  );
};

export const useWhatsappContext = () => useContext(WhatsappContext);
