import React from "react";
import { BsArrowUpRightSquareFill } from "react-icons/bs";
import { BiSolidCopy } from "react-icons/bi";
import { FaPhone } from "react-icons/fa";
import { MdReply } from "react-icons/md";

import imagePlaceholder from "../../images/props/imagePlaceholder.png";
import videoPlaceholder from "../../images/props/videoPlaceholder.png";
import documentPlaceholder from "../../images/props/documentPlaceholder.png";
import { parseText } from "./parseText.js";

export const viewTemplate = (element, showPlaceholder) => {
  if (element?.type?.toUpperCase() === "BUTTONS") {
    return element?.buttons?.map((button) => (
      <button
        key={button?.text}
        className="w-full p-2 flex justify-center items-center gap-1 text-xs border-t-2 border-slate-100 my-1"
      >
        {button?.type === "URL" ? (
          <BsArrowUpRightSquareFill />
        ) : button?.type === "PHONE_NUMBER" ? (
          <FaPhone />
        ) : button?.type === "COPY_CODE" ? (
          <BiSolidCopy />
        ) : (
          <MdReply />
        )}
        {button?.text}
      </button>
    ));
  } else {
    return (
      <div key={element?.type} className="w-full py-1 text-xs">
        {element?.type?.toUpperCase() === "HEADER" ? (
          <>
            {element?.format === "TEXT" && (
              <h1 className="px-2 font-bold">{element.text}</h1>
            )}
            {showPlaceholder && element?.format === "IMAGE" && (
              <img
                src={imagePlaceholder}
                className="w-full px-1 rounded-md"
                alt="placeholder"
              />
            )}
            {showPlaceholder && element?.format === "VIDEO" && (
              <img
                src={videoPlaceholder}
                className="w-full px-1 rounded-md"
                alt="placeholder"
              />
            )}
            {showPlaceholder && element?.format === "DOCUMENT" && (
              <img
                src={documentPlaceholder}
                className="w-full px-1 rounded-md"
                alt="placeholder"
              />
            )}
          </>
        ) : (
          <></>
        )}
        {element?.type?.toUpperCase() === "BODY" ? (
          <p className="px-2">{parseText(element.text)}</p>
        ) : (
          <></>
        )}
        {element?.type?.toUpperCase() === "FOOTER" ? (
          <h4 className="px-2 text-gray-500 text-xs">{element.text}</h4>
        ) : (
          <></>
        )}
      </div>
    );
  }
};
