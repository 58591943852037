import { React } from "react";
import { NavLink } from "react-router-dom";
import Popup from "reactjs-popup";
import { AiTwotoneShop } from "react-icons/ai";
import { BsCaretLeftSquareFill, BsCaretRightSquareFill } from "react-icons/bs";
import {
  FaPowerOff,
  FaQuestion,
  FaLock,
  // FaPrayingHands,
  FaReplyAll,
} from "react-icons/fa";
import { GiOpenBook } from "react-icons/gi";
import { HiTemplate } from "react-icons/hi";
import { MdMarkChatRead, MdPayments } from "react-icons/md";
import { IoMdChatbubbles } from "react-icons/io";
import { IoSettingsSharp } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import {
  MdPermContactCalendar,
  MdMonitorHeart,
  MdNotificationsActive,
  MdQuickreply,
} from "react-icons/md";
import { PiUsersFill, PiArrowFatLineLeftFill } from "react-icons/pi";
import { RiMailSendFill, RiTimer2Fill } from "react-icons/ri";

import wabmLogo from "../images/logo/wabm-name-icon-360px.png";
import { useUserContext } from "../contexts/UserContextProvider";
import { useConversationContext } from "../contexts/ConversationContextProvider";

const Sidebar = () => {
  const {
    apiCallLogout,
    currentUserInfo,
    isUserAdminOrSuperadmin,
    hideSidebar,
    setHideSidebar,
  } = useUserContext();
  const { messageNotificationCount, setMessageNotificationCount } =
    useConversationContext();

  const location = useLocation();
  // const [resetNotificationCount, setResetNotificationCount] = useState(null);

  return (
    <div className="bg-slate-900 md:min-w-fit md:h-screen px-5 md:px-0 flex md:flex-col justify-between text-white gap-5 md:gap-0 md:overflow-x-hidden md:overflow-y-auto z-10">
      <div className="py-3 md:py-3 relative flex flex-row md:flex-col flex-wrap justify-center md:justify-evenly gap-3 md:gap-0">
        <img
          src={wabmLogo}
          className="w-auto mx-auto mb-2 h-20 hidden md:block"
          alt="wabm"
        />
        <button
          type="button"
          onClick={() => setHideSidebar(!hideSidebar)}
          title={hideSidebar ? "Show" : "Hide"}
          className={
            "my-hover-effect w-fit hidden md:block md:absolute md:top-20 md:right-0"
          }
        >
          {hideSidebar ? (
            <BsCaretRightSquareFill size={20} />
          ) : (
            <BsCaretLeftSquareFill size={20} />
          )}
        </button>
        <NavLink
          to="/"
          title={hideSidebar ? "Go Back Home" : ""}
          className="my-button-style-2 hidden md:inline-flex md:gap-2 md:border-b-[0.5px] md:border-slate-600"
        >
          <PiArrowFatLineLeftFill size={24} />
          {!hideSidebar && <p>Back Home</p>}
        </NavLink>
        <NavLink
          to="/dashboard/overview"
          title={hideSidebar ? "Overview" : ""}
          className={({ isActive }) =>
            isActive
              ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
              : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
          }
        >
          <span className="w-full flex gap-2">
            <AiTwotoneShop size="1.5rem" />
            {!hideSidebar && <h3 className="hidden md:block">Dashboard</h3>}
          </span>
        </NavLink>
        <NavLink
          to="/dashboard/chats"
          title={hideSidebar ? "Chats" : ""}
          className={({ isActive }) =>
            isActive
              ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
              : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
          }
        >
          <span
            className="w-full flex items-center gap-2"
            onClick={() => {
              setMessageNotificationCount(0);
              // setResetNotificationCount(0);
            }}
          >
            <IoMdChatbubbles size="1.5rem" />
            {!hideSidebar && <h3 className="hidden md:block">Chats</h3>}
            {messageNotificationCount > 0 &&
              location?.pathname !== "/dashboard/chats" && (
                <span className="bg-red-500 w-6 h-6 p-1 grid place-items-center text-white text-xs font-bold rounded-full">
                  {messageNotificationCount}
                </span>
              )}
          </span>
        </NavLink>
        <NavLink
          to="/dashboard/templates"
          title={hideSidebar ? "Templates" : ""}
          className={({ isActive }) =>
            isActive
              ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
              : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
          }
        >
          <span className="w-full flex gap-2">
            <HiTemplate size="1.5rem" />
            {!hideSidebar && <h3 className="hidden md:block">Templates</h3>}
          </span>
        </NavLink>
        <NavLink
          to="/dashboard/campaign"
          title={hideSidebar ? "Campaign" : ""}
          className={({ isActive }) =>
            isActive
              ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
              : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
          }
        >
          <span className="w-full flex gap-2">
            <RiMailSendFill size="1.5rem" />
            {!hideSidebar && <h3 className="hidden md:block">Campaign</h3>}
            {(!currentUserInfo?.isActiveSubscription ||
              (currentUserInfo?.pricingModel === 1 &&
                currentUserInfo?.balanceCoins === 0)) && <FaLock size={10} />}
          </span>
        </NavLink>
        <NavLink
          to="/dashboard/monitor"
          title={hideSidebar ? "Monitor Metrics" : ""}
          className={({ isActive }) =>
            isActive
              ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
              : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
          }
        >
          <span className="w-full flex gap-2">
            <MdMonitorHeart size="1.5rem" />
            {!hideSidebar && <h3 className="hidden md:block">Monitor</h3>}
            {(!currentUserInfo?.isActiveSubscription ||
              (currentUserInfo?.pricingModel === 1 &&
                currentUserInfo?.balanceCoins === 0)) && <FaLock size={10} />}
          </span>
        </NavLink>

        {currentUserInfo?.catalogId && currentUserInfo?.catalogId !== "" ? (
          <NavLink
            to="/dashboard/catalog"
            title={hideSidebar ? "Catalog" : ""}
            className={({ isActive }) =>
              isActive
                ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
                : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
            }
          >
            <span className="w-full flex gap-2">
              <GiOpenBook size="1.5rem" />
              {!hideSidebar && <h3 className="hidden md:block">Catalog</h3>}
              {(!currentUserInfo?.isActiveSubscription ||
                (currentUserInfo?.pricingModel === 1 &&
                  currentUserInfo?.balanceCoins === 0)) && <FaLock size={10} />}
            </span>
          </NavLink>
        ) : (
          <></>
        )}

        {isUserAdminOrSuperadmin() ? (
          <NavLink
            to="/dashboard/contacts"
            title={hideSidebar ? "Contacts" : ""}
            className={({ isActive }) =>
              isActive
                ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
                : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
            }
          >
            <span className="w-full flex gap-2">
              <MdPermContactCalendar size="1.5rem" />
              {!hideSidebar && <h3 className="hidden md:block">Contacts</h3>}
              {(!currentUserInfo?.isActiveSubscription ||
                (currentUserInfo?.pricingModel === 1 &&
                  currentUserInfo?.balanceCoins === 0)) && <FaLock size={10} />}
            </span>
          </NavLink>
        ) : (
          <></>
        )}

        {isUserAdminOrSuperadmin() ? (
          <NavLink
            to="/dashboard/agents"
            title={hideSidebar ? "Agents" : ""}
            className={({ isActive }) =>
              isActive
                ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
                : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
            }
          >
            <span className="w-full flex gap-2">
              <PiUsersFill size="1.5rem" />
              {!hideSidebar && <h3 className="hidden md:block">Agents</h3>}
              {(!currentUserInfo?.isActiveSubscription ||
                (currentUserInfo?.pricingModel === 1 &&
                  currentUserInfo?.balanceCoins === 0)) && <FaLock size={10} />}
            </span>
          </NavLink>
        ) : (
          <></>
        )}

        {/* {isUserAdminOrSuperadmin() ? (
          <NavLink
            to="/dashboard/welcome-messages"
            title="Welcome Messages"
            className={({ isActive }) =>
              isActive
                ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
                : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
            }
          >
            <span className="w-full flex gap-2">
              <FaPrayingHands size="1.5rem" />
              {!hideSidebar && <h3>Welcome Messages</h3>}
            </span>
          </NavLink>
        ) : (
          <></>
        )} */}

        {isUserAdminOrSuperadmin() ? (
          <NavLink
            to="/dashboard/auto-replies"
            title="Auto Replies"
            className={({ isActive }) =>
              isActive
                ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
                : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
            }
          >
            <span className="w-full flex gap-2">
              <FaReplyAll size="1.5rem" />
              {!hideSidebar && <h3>Auto Replies</h3>}
            </span>
          </NavLink>
        ) : (
          <></>
        )}

        {isUserAdminOrSuperadmin() ? (
          <NavLink
            to="/dashboard/follow-ups"
            title={hideSidebar ? "Follow-Ups" : ""}
            className={({ isActive }) =>
              isActive
                ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
                : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
            }
          >
            <span className="w-full flex gap-2">
              <RiTimer2Fill size="1.5rem" />
              {!hideSidebar && <h3 className="hidden md:block">Follow-Ups</h3>}
              {(!currentUserInfo?.isActiveSubscription ||
                (currentUserInfo?.pricingModel === 1 &&
                  currentUserInfo?.balanceCoins === 0)) && <FaLock size={10} />}
            </span>
          </NavLink>
        ) : (
          <></>
        )}

        {isUserAdminOrSuperadmin() ? (
          <NavLink
            to="/dashboard/assigned-chats"
            title={hideSidebar ? "Assigned Chats" : ""}
            className={({ isActive }) =>
              isActive
                ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
                : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
            }
          >
            <span className="w-full flex gap-2">
              <MdMarkChatRead size="1.5rem" />
              {!hideSidebar && (
                <h3 className="hidden md:block">Assigned Chats</h3>
              )}
              {(!currentUserInfo?.isActiveSubscription ||
                (currentUserInfo?.pricingModel === 1 &&
                  currentUserInfo?.balanceCoins === 0)) && <FaLock size={10} />}
            </span>
          </NavLink>
        ) : (
          <></>
        )}

        <NavLink
          to="/dashboard/notifications"
          title={hideSidebar ? "Notifications" : ""}
          className={({ isActive }) =>
            isActive
              ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
              : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
          }
        >
          <span className="w-full flex gap-2">
            <MdNotificationsActive size="1.5rem" />
            {!hideSidebar && <h3 className="hidden md:block">Notifications</h3>}
          </span>
        </NavLink>

        <NavLink
          to="/dashboard/quick-reply"
          title={hideSidebar ? "Quick Reply" : ""}
          className={({ isActive }) =>
            isActive
              ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
              : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
          }
        >
          <span className="w-full flex gap-2">
            <MdQuickreply size="1.5rem" />
            {!hideSidebar && <h3 className="hidden md:block">Quick Reply</h3>}
            {(!currentUserInfo?.isActiveSubscription ||
              (currentUserInfo?.pricingModel === 1 &&
                currentUserInfo?.balanceCoins === 0)) && <FaLock size={10} />}
          </span>
        </NavLink>

        {/* <NavLink
          to="/dashboard/message-flow"
          title={hideSidebar ? "Design message flow" : ""}
          className={({ isActive }) =>
            isActive
              ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
              : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
          }
        >
          <span className="w-full flex gap-2">
            <IoLogoFigma size="1.5rem" />
            {!hideSidebar && (
              <h3 className="hidden md:block">Design Message Flow</h3>
            )}
          </span>
        </NavLink> */}

        {isUserAdminOrSuperadmin() && currentUserInfo?.pricingModel === 0 ? (
          <NavLink
            to="/dashboard/subscriptions"
            title={hideSidebar ? "Subscriptions" : ""}
            className={({ isActive }) =>
              isActive
                ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
                : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
            }
          >
            <span className="w-full flex gap-2">
              <MdPayments size="1.5rem" />
              {!hideSidebar && (
                <h3 className="hidden md:block">Subscriptions</h3>
              )}
            </span>
          </NavLink>
        ) : (
          <></>
        )}

        {isUserAdminOrSuperadmin() && currentUserInfo?.pricingModel === 1 ? (
          <NavLink
            to="/dashboard/recharge"
            title={hideSidebar ? "Recharge" : ""}
            className={({ isActive }) =>
              isActive
                ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
                : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
            }
          >
            <span className="w-full flex gap-2">
              <MdPayments size="1.5rem" />
              {!hideSidebar && <h3 className="hidden md:block">Recharge</h3>}
            </span>
          </NavLink>
        ) : (
          <></>
        )}

        {/* <NavLink
          to="/dashboard/addons"
          title={hideSidebar ? "Subscription Add-ons" : ""}
          className={({ isActive }) =>
            isActive
              ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
              : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
          }
        >
          <span className="w-full flex gap-2">
            <SiPacker size="1.5rem" />
            {!hideSidebar && <h3 className="hidden md:block">Add-ons</h3>}
            {!currentUserInfo?.isActiveSubscription && <FaLock size={10} />}
          </span>
        </NavLink> */}

        <NavLink
          to="/dashboard/settings"
          title={hideSidebar ? "Settings" : ""}
          className={({ isActive }) =>
            isActive
              ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
              : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
          }
        >
          <span className="w-full flex gap-2">
            <IoSettingsSharp size="1.5rem" />
            {!hideSidebar && <h3 className="hidden md:block">Settings</h3>}
          </span>
        </NavLink>
      </div>

      <div className="py-3 md:py-3 flex flex-row md:flex-col flex-wrap justify-center md:justify-evenly gap-3 md:gap-0">
        <NavLink
          to="/faq"
          title={hideSidebar ? "FAQs" : ""}
          className="my-button-style-2 hidden md:block md:border-b-[0.5px] md:border-slate-600"
        >
          <span className="w-full flex gap-2">
            <FaQuestion size="1.5rem" />
            {!hideSidebar && <h3 className="hidden md:block">Help</h3>}
          </span>
        </NavLink>

        {/* Logout for Small Screen Devices */}
        <span className="md:hidden w-full">
          <Popup
            trigger={
              <button
                title={hideSidebar ? "Log Out" : ""}
                className="my-button-style-2 w-full text-red-600 hover:text-red-500"
              >
                <span className="w-full flex gap-2">
                  <FaPowerOff size="1.4rem" />
                  {!hideSidebar && <h3 className="hidden md:block">Log Out</h3>}
                </span>
              </button>
            }
            position="left top"
            className="hidden"
          >
            <div className="my-popup-style-1">
              <p>Are you sure you want to log out?</p>
              <button
                onClick={apiCallLogout}
                className="my-button-style-cancel mt-2 flex items-center gap-1 text-sm"
              >
                <FaPowerOff size="0.8rem" />
                Log Out
              </button>
            </div>
          </Popup>
        </span>
        {/* Logout for Medium to Large Screen Devices */}
        <span className="hidden md:inline">
          <Popup
            trigger={
              <button
                title={hideSidebar ? "Log Out" : ""}
                className="my-button-style-2 w-full text-red-600 hover:text-red-500"
              >
                <span className="w-full flex gap-2">
                  <FaPowerOff size="1.4rem" />
                  {!hideSidebar && <h3 className="hidden md:block">Log Out</h3>}
                </span>
              </button>
            }
            position="right bottom"
            className="hidden"
          >
            <div className="my-popup-style-1">
              <p>Are you sure you want to log out?</p>
              <button
                onClick={apiCallLogout}
                className="my-button-style-cancel mt-2 flex items-center gap-1 text-sm"
              >
                <FaPowerOff size="0.8rem" />
                Log Out
              </button>
            </div>
          </Popup>
        </span>
      </div>
    </div>
  );
};

export default Sidebar;
