import { React, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { AiFillSchedule } from "react-icons/ai";
import { MdDescription } from "react-icons/md";
import * as XLSX from "xlsx";
import {
  Button,
  Select,
  Option,
  Dialog,
  DialogHeader,
  DialogBody,
} from "@material-tailwind/react";

import { colorsConfig } from "../../config/colorsConfig";
import { useContactsContext } from "../../contexts/ContactsContextProvider";
import { Loader } from "..";
import { isoTimestampToLocal } from "../utils/timeStampConversion";

const ViewTrackingContactsTable = ({ scheduleStatus, jobId, selectedJob }) => {
  const {
    scheduledCampaignsTrackingData,
    isLoading,
    contactGroups,
    apiCallAssignContactGroups,
    apiCallFetchContactGroups,
    apiResendCampaign,
    isProcessing,
  } = useContactsContext();

  const [isVisibleSendButton, setIsVisibleSendButton] = useState(false);
  const [selectedContact, setSelectedContact] = useState([]);
  const [selectedContactToAddGroup, setSelectedContactToAddGroup] = useState(
    []
  );
  const [targetContactGroup, setTargetContactGroup] = useState("");
  const [openGroupCreationDialog, setOpenGroupCreationDialog] = useState(false);

  const assignContactGroupsOnSubmit = async (event) => {
    event.preventDefault();

    if (contactGroups.filter((x) => x.groupName === targetContactGroup)) {
      const result = await apiCallAssignContactGroups(
        selectedContactToAddGroup,
        targetContactGroup
      );

      if (result?.success) {
        setOpenGroupCreationDialog(false);
        setIsVisibleSendButton(false);
      }
    }
  };

  let selectedFailedContact = [];
  let selectedContactToGroup = [];

  const scheduleTrackingTableColumns = [
    {
      name: "Contact Number",
      selector: (row) => row?.ContactNumber,
    },
    {
      name: "Contact Name",
      selector: (row) => row?.contactName,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      center: true,
    },
    {
      name: "Company",
      selector: (row) => row?.companyName,
    },
    {
      name: "City",
      selector: (row) => row?.city,
    },
    {
      name: "Send Status",
      selector: (row) => row?.MessageStatus,
      center: true,
      sortable: true,
    },
    {
      name: "Message Status",
      // selector: (row) => (
      //   <p
      //     className={
      //       row?.MessageStatusFromWhatsapp === "read"
      //         ? "text-green-500"
      //         : row?.MessageStatusFromWhatsapp === "delivered"
      //         ? "text-blue-500"
      //         : row?.MessageStatusFromWhatsapp === "failed"
      //         ? "text-red-500"
      //         : "text-slate-700"
      //     }
      //   >
      //     {row?.MessageStatusFromWhatsapp}
      //   </p>
      // ),
      selector: (row) => row?.MessageStatusFromWhatsapp,
      center: true,
      sortable: true,
    },
    {
      name: "Message Remarks",
      selector: (row) => row?.failed_reason,
      center: false,
      wrap: true,
      grow: 2,
    },
    {
      name: "Error Code",
      selector: (row) => row?.failed_reason_error_code,
      center: true,
      grow: 2,
    },
  ];

  const handleChangeOnSelect = ({ selectedRows }) => {
    selectedFailedContact = [];
    selectedContactToGroup = [];
    selectedRows?.map((x) => selectedFailedContact.push(x.ContactNumber));
    selectedRows?.map((x) =>
      selectedContactToGroup.push(
        JSON.stringify({
          phoneNo: x.phone_number_c,
          countryCode: x.country_code_si,
        })
      )
    );

    if (selectedFailedContact.length > 0) {
      setSelectedContact(selectedFailedContact);
      setSelectedContactToAddGroup(selectedContactToGroup);
      setIsVisibleSendButton(true);
    } else {
      setSelectedContact([]);
      setIsVisibleSendButton(false);
    }
  };

  const handleResend = async (e) => {
    e.preventDefault();
    await apiResendCampaign({
      scheduleId: jobId,
      selectedContacts: selectedContact,
      scheduleStatus: scheduleStatus,
    });
  };

  const downloadAllContactsOnClick = async () => {
    let contactArray = [];
    // eslint-disable-next-line array-callback-return
    scheduledCampaignsTrackingData?.map((contact) => {
      contactArray.push({
        "Contact Number": contact?.ContactNumber,
        "Contact Name": contact?.contactName,
        Email: contact?.email,
        Company: contact?.companyName,
        City: contact?.city,
        "Send Status": contact?.MessageStatus,
        "Message Status": contact?.MessageStatusFromWhatsapp,
      });
    });

    const dataForForDownload = contactArray;
    const worksheet = XLSX.utils.json_to_sheet(dataForForDownload);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(
      workbook,
      `Campaign Data - ${selectedJob?.scheduleName} - ${isoTimestampToLocal(
        selectedJob?.scheduleDateTime
      )}.xlsx`
    );
  };

  useEffect(() => {
    apiCallFetchContactGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="p-5 space-y-2">
      <h3 className="wabm-font-style-2 text-white">Message Tracking Status</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
        <div className="space-y-2">
          <span className="flex items-center gap-2 text-white">
            <AiFillSchedule size={24} />
            <h4 className="text-xl font-bold">{selectedJob?.scheduleName}</h4>
          </span>
          {selectedJob?.scheduleDescription && (
            <span className="flex items-center gap-2 text-white">
              <MdDescription size={16} />
              <p className="text-white">{selectedJob?.scheduleDescription}</p>
            </span>
          )}
          <Button
            color="green"
            size="sm"
            onClick={() => downloadAllContactsOnClick()}
            className="w-fit px-5 whitespace-nowrap"
          >
            Download Report
          </Button>
        </div>
        <div className="space-y-3 text-white text-sm">
          <p>
            Created : {isoTimestampToLocal(selectedJob?.scheduleCreationDate)}
          </p>
          <p>
            Scheduled : {isoTimestampToLocal(selectedJob?.scheduleDateTime)}
          </p>
          <p>Status : {selectedJob?.scheduleStatus}</p>
        </div>
        {isVisibleSendButton && (
          <div className="flex items-center gap-2">
            <p className="text-white">Action :</p>
            <Button
              color="yellow"
              size="sm"
              disabled={isProcessing}
              onClick={handleResend}
            >
              {isProcessing ? <span>Sending...</span> : <span>Resend</span>}
            </Button>
            <Button
              color="blue"
              size="sm"
              onClick={() => {
                setOpenGroupCreationDialog(true);
              }}
            >
              <span>Add to contact group</span>
            </Button>
          </div>
        )}
      </div>

      <div className="w-full max-h-[90vh] pt-3 rounded-md overflow-auto">
        {isLoading ? (
          <Loader />
        ) : scheduledCampaignsTrackingData.length > 0 ? (
          <DataTable
            fixedHeader
            selectableRows
            onSelectedRowsChange={handleChangeOnSelect}
            columns={scheduleTrackingTableColumns}
            data={scheduledCampaignsTrackingData}
            fixedHeaderScrollHeight="75vh"
            customStyles={scheduleTableStyles}
          />
        ) : (
          <div className="space-y-3">
            <p>No Tracking data found.</p>
          </div>
        )}
      </div>

      <Dialog
        open={openGroupCreationDialog}
        handler={() => setOpenGroupCreationDialog(!openGroupCreationDialog)}
      >
        <span className="flex justify-between gap-3">
          <DialogHeader>Add Contact Group</DialogHeader>
        </span>
        <DialogBody>
          <form
            onSubmit={assignContactGroupsOnSubmit}
            className="flex flex-row md:flex-nowrap items-center gap-3"
          >
            <Select
              variant="outlined"
              label="Select Contact Group"
              color="blue"
              required
              value={targetContactGroup}
              onChange={(e) => setTargetContactGroup(e)}
              className="w-full"
            >
              {contactGroups?.map((group, index) => (
                <Option key={index} value={group?.groupName}>
                  {group?.groupName} (
                  {group?.numberOfContacts ? group.numberOfContacts : 0}{" "}
                  contacts)
                </Option>
              ))}
            </Select>
            <div className="flex flex-row items-center gap-2">
              <Button type="submit" variant="gradient" color="green">
                <span className="whitespace-nowrap">Add to Group</span>
              </Button>
            </div>
          </form>
        </DialogBody>
      </Dialog>
    </main>
  );
};

export default ViewTrackingContactsTable;

const scheduleTableStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      backgroundColor: colorsConfig.wabmBlue,
      color: "#FFFFFF",
      fontSize: "0.9rem",
    },
  },
  cells: {
    style: {},
  },
};
