import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import {
  Drawer,
  Button,
  Typography,
  Select,
  Option,
  Input,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  IconButton,
} from "@material-tailwind/react";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import { HiArrowDownCircle } from "react-icons/hi2";
import { IoClose } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";
import Popup from "reactjs-popup";

import { colorsConfig } from "../../config/colorsConfig";
import {
  uploadMediaOnAWS,
  EnumFileUploadStatus,
} from "../../helpers/AWSService.js";
import { useConversationContext } from "../../contexts/ConversationContextProvider";
import { useUserContext } from "../../contexts/UserContextProvider";
import { CustomLoader } from "..";

const ManageAutoReplies = () => {
  const {
    createAutoReplyMessage,
    getAutoReplyMessages,
    removeAutoReplyMessage,
  } = useConversationContext();
  const { isLoading } = useUserContext();

  const [toCreateAutoReplies, setToCreateAutoReplies] = useState(false);
  const [autoRepliesMessageBodyText, setAutoRepliesMessageBodyText] =
    useState("");
  const [triggerCondition, setTriggerCondition] = useState("");
  // const [autoRepliesDelay, setAutoRepliesDelay] = useState("");
  const [autoRepliesMessageList, setAutoRepliesMessageList] = useState([]);
  const [refreshPageCounter, setRefreshPageCounter] = useState(0);

  const weekDay = [
    {
      key: 0,
      text: "Sunday",
    },
    {
      key: 1,
      text: "Monday",
    },
    {
      key: 2,
      text: "Tuesday",
    },
    {
      key: 3,
      text: "Wednesday",
    },
    {
      key: 4,
      text: "Thursday",
    },
    {
      key: 5,
      text: "Friday",
    },
    {
      key: 6,
      text: "Saturday",
    },
  ];

  const blankMessage = {
    messageType: "text",
    bodyText: "",
    mediaValue: null,
  };
  const [listOfAutoReplies, setListOfAutoReplies] = useState([blankMessage]);
  const defaultInputValue = {
    minutes: "",
    hours: "",
    days: "",
    date: "",
    time: "",
    text: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    weekDay: "",
    triggerInHour: 24,
  };
  const [inputData, setInputData] = useState(defaultInputValue);

  const autoRepliesTableColumns = [
    {
      name: "Message Type",
      selector: (row) => row?.messageType,
      sortable: true,
    },
    {
      name: "Reply Message",
      selector: (row) => row?.replyText,
      sortable: true,
      wrap: true,
    },
    {
      name: "Trigger Condition Type",
      selector: (row) => row?.triggerConditionType,
      sortable: true,
    },
    {
      name: "Auto Reply Delay",
      selector: (row) => row?.autoReplyDelay,
      sortable: true,
    },
    {
      name: "Text",
      selector: (row) => row?.triggerCondition?.text,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <div className="flex gap-3 w-96">
            <Menu>
              <MenuHandler>
                <IconButton variant="text">
                  <HiArrowDownCircle className="h-7 w-7" />
                </IconButton>
              </MenuHandler>
              <MenuList>
                <MenuItem>
                  <Popup
                    trigger={
                      <Button color="red" className="w-36">
                        Delete Message
                      </Button>
                    }
                    position="left bottom"
                    className="hidden"
                  >
                    <div className="my-popup-style-1">
                      <p>
                        Are you sure you want to delete "{row?.messageType}
                        "?
                      </p>
                      <button
                        type="button"
                        onClick={() => handleDeleteOnClick(row?._id)}
                        className="my-button-style-cancel mt-2 flex items-center gap-1 text-sm"
                      >
                        <MdDeleteOutline size="0.8rem" />
                        Delete Message
                      </button>
                    </div>
                  </Popup>
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
        );
      },
      sortable: true,
    },
  ];

  const handleSelectOnChange = (index, newMessageType) => {
    const updatedMessages = listOfAutoReplies?.map((item, i) =>
      i === index
        ? {
            ...item,
            messageType: newMessageType,
            bodyText: "",
            mediaValue: null,
          }
        : item
    );
    setListOfAutoReplies(updatedMessages);
  };

  const handleInputOnChange = (index, value, type) => {
    const updatedMessages = listOfAutoReplies?.map((item, i) =>
      i === index ? { ...item, [type]: value } : item
    );
    setAutoRepliesMessageBodyText(value);
    setListOfAutoReplies(updatedMessages);
  };

  const handleTriggerConditionChange = (e) => {
    setInputData(defaultInputValue);
    setTriggerCondition(e);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (value, name) => {
    setInputData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAutoRepliesMessageOnSubmit = async (e) => {
    e.preventDefault();

    let hasBlankBodyText = false;
    let autoRepliesMessages = {};
    for (let i = 0; i < listOfAutoReplies.length; i++) {
      const message = listOfAutoReplies[i];

      if (message.messageType !== "text" && message.mediaValue) {
        const file = message.mediaValue;
        const fileName = await uploadMedia(file);
        autoRepliesMessages = {
          message_type: message.messageType,
          bodyText: fileName,
        };
      } else {
        if (!message.bodyText || message.bodyText.trim() === "") {
          toast.error(`Message body text is required`);
          hasBlankBodyText = true;
          return;
        } else {
          autoRepliesMessages = {
            message_type: message.messageType,
            bodyText: message.bodyText,
          };
        }
      }
      if (!hasBlankBodyText && i === listOfAutoReplies.length - 1) {
        autoRepliesMessages.triggerConditionType = triggerCondition;
        autoRepliesMessages.triggerCondition = inputData;
        // autoRepliesMessages.autoReplyDelay = autoRepliesDelay;
        await createAutoReplyMessage(autoRepliesMessages);
        setRefreshPageCounter((prvCounter) => prvCounter + 1);
        setToCreateAutoReplies(false);
      }
    }
  };

  const uploadMedia = async (uploadedFile) => {
    try {
      const uniqueId = uuid();
      const uniqueFileName =
        uniqueId + "." + uploadedFile.name.split(".").pop().toLowerCase();
      const uploadParams = {
        fileName: uniqueFileName,
        mediaFile: uploadedFile,
        fileType: uploadedFile.type,
      };

      const response = await uploadMediaOnAWS(uploadParams);
      if (response === EnumFileUploadStatus.FileUploadSuccess) {
        return uniqueFileName;
      } else {
        return "";
      }
    } catch (err) {
      console.log("auto replies messages uploadMedia err", err);
      return "";
    }
  };

  const loadAutoRepliesMessages = async () => {
    const response = await getAutoReplyMessages();
    setAutoRepliesMessageList(response);
  };

  const handleDeleteOnClick = async (messageId) => {
    await removeAutoReplyMessage(messageId);
    setRefreshPageCounter((prvCounter) => prvCounter + 1);
  };

  const handleScrollOnScroll = (e) => {
    // Prevent scrolling from bubbling up to parent elements
    e.stopPropagation();
  };

  const resetStates = () => {
    setToCreateAutoReplies(false);
    setListOfAutoReplies([blankMessage]);
  };

  useEffect(() => {
    loadAutoRepliesMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPageCounter]);

  const renderInputs = () => {
    switch (triggerCondition) {
      case "timepassed":
        return (
          <div className="space-y-2">
            <div className="grid grid-cols-3 w-full">
              <Input
                variant="outlined"
                color="green"
                type="number"
                name="days"
                containerProps={{
                  className: "min-w-0",
                }}
                value={inputData.days}
                onChange={handleInputChange}
                label="Days"
              />
              <Input
                variant="outlined"
                color="green"
                type="number"
                name="hours"
                containerProps={{
                  className: "min-w-0",
                }}
                value={inputData.hours}
                onChange={handleInputChange}
                label="Hours"
              />
              <Input
                variant="outlined"
                color="green"
                type="number"
                name="minutes"
                containerProps={{
                  className: "min-w-0",
                }}
                value={inputData.minutes}
                onChange={handleInputChange}
                label="Minutes"
              />
            </div>
            <Input
              variant="outlined"
              color="green"
              type="number"
              name="triggerInHour"
              className="w-[100%]"
              onChange={handleInputChange}
              value={inputData.triggerInHour}
              label="Repeat this auto reply in x hours"
            />
          </div>
        );
      case "timetocome":
        return (
          <div className="space-y-2">
            <div className="grid grid-cols-2 gap-2 w-full">
              <Input
                variant="outlined"
                color="green"
                type="date"
                name="date"
                containerProps={{
                  className: "min-w-0",
                }}
                value={inputData.date}
                onChange={handleInputChange}
              />
              <Input
                variant="outlined"
                color="green"
                type="time"
                name="time"
                containerProps={{
                  className: "min-w-0",
                }}
                value={inputData.time}
                onChange={handleInputChange}
              />
            </div>
            <Input
              variant="outlined"
              color="green"
              type="number"
              name="triggerInHour"
              className="w-[100%]"
              onChange={handleInputChange}
              value={inputData.triggerInHour}
              label="Repeat this auto reply in x hours"
            />
          </div>
        );
      case "datetimeinbetween":
        return (
          <div className="space-y-2">
            <div className="flex flex-row gap-2">
              <Input
                variant="outlined"
                color="green"
                type="date"
                containerProps={{
                  className: "min-w-0",
                }}
                name="startDate"
                value={inputData.startDate}
                onChange={handleInputChange}
              />
              <Input
                variant="outlined"
                color="green"
                type="time"
                containerProps={{
                  className: "min-w-0",
                }}
                name="startTime"
                value={inputData.startTime}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex flex-row gap-2">
              <Input
                variant="outlined"
                color="green"
                type="date"
                name="endDate"
                containerProps={{
                  className: "min-w-0",
                }}
                value={inputData.endDate}
                onChange={handleInputChange}
              />
              <Input
                variant="outlined"
                color="green"
                type="time"
                name="endTime"
                containerProps={{
                  className: "min-w-0",
                }}
                value={inputData.endTime}
                onChange={handleInputChange}
              />
            </div>
            <Input
              variant="outlined"
              color="green"
              type="number"
              name="triggerInHour"
              className="w-[100%]"
              onChange={handleInputChange}
              value={inputData.triggerInHour}
              label="Repeat this auto reply in x hours"
            />
          </div>
        );
      case "daytimeinbetween":
        return (
          <div className="space-y-2">
            <div className="flex flex-row gap-2">
              <Select
                variant="standard"
                color="blue"
                name="weekDay"
                required
                label="Select Day"
                value={inputData?.weekDay}
                onChange={(e) => {
                  handleSelectChange(e, "weekDay");
                }}
                className="my-input-box-1"
              >
                {weekDay?.map((item) => (
                  <Option
                    key={item?.key}
                    value={item?.key}
                    className={`hover:bg-blue-300 focus:bg-blue-300`}
                  >
                    {item?.text}
                  </Option>
                ))}
              </Select>
              <Input
                variant="outlined"
                color="green"
                type="time"
                containerProps={{
                  className: "custom-width-auto",
                }}
                name="startTime"
                value={inputData.startTime}
                onChange={handleInputChange}
              />
              <Input
                variant="outlined"
                color="green"
                type="time"
                name="endTime"
                containerProps={{
                  className: "custom-width-auto",
                }}
                value={inputData.endTime}
                onChange={handleInputChange}
              />
            </div>
            <Input
              variant="outlined"
              color="green"
              type="number"
              name="triggerInHour"
              className="w-[100%]"
              onChange={handleInputChange}
              value={inputData.triggerInHour}
              label="Repeat this auto reply in (Hour)"
            />
          </div>
        );
      case "exacttext":
      case "buttonreply":
      case "containstext":
        return (
          <Input
            variant="outlined"
            color="green"
            type="text"
            name="text"
            label="Enter text"
            value={inputData.text}
            onChange={handleInputChange}
            placeholder="Enter text"
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && <CustomLoader />}
      <main className="w-full mt-5 mb-10 lg:my-1 space-y-5">
        <div className="w-full space-y-2">
          <h1 className="text-2xl">Manage Auto Replies</h1>
          <h3>Create, Delete and Manage Auto Replies messages.</h3>
        </div>
        <Button
          type="button"
          variant="gradient"
          color="blue"
          onClick={() => {
            setListOfAutoReplies([blankMessage]);
            setTriggerCondition("");
            setInputData(defaultInputValue);
            setToCreateAutoReplies(true);
          }}
        >
          Create Auto Replies
        </Button>
        <div className="space-y-2 rounded-md overflow-auto">
          <DataTable
            columns={autoRepliesTableColumns}
            data={autoRepliesMessageList}
            customStyles={replyTableStyles}
            fixedHeader
            fixedHeaderScrollHeight="60vh"
          />
        </div>
      </main>
      <Drawer
        placement="right"
        onScroll={handleScrollOnScroll}
        open={toCreateAutoReplies}
        onClose={() => setToCreateAutoReplies(false)}
        size="650px"
        className="w-full p-5 z-[9996] overflow-y-scroll"
      >
        <form onSubmit={handleAutoRepliesMessageOnSubmit} className="space-y-3">
          <div className="flex justify-center flex-row">
            <Typography
              variant="h4"
              color="blue"
              className="text-center items-center"
            >
              Create Auto Reply
            </Typography>
            <button
              type="button"
              onClick={() => resetStates()}
              className="my-button-style-cancel absolute top-5 right-5 items-end"
            >
              <IoClose size={18} />
            </button>
          </div>

          {listOfAutoReplies?.map((item, index) => (
            <div key={index} className="w-full mt-3 space-y-5">
              <Select
                variant="standard"
                color="blue"
                required
                label="Message Type"
                value={item?.messageType}
                onChange={(e) => handleSelectOnChange(index, e)}
                className="my-input-box-1"
              >
                <Option
                  key="text"
                  value="text"
                  className={`hover:bg-blue-300 focus:bg-blue-300`}
                >
                  Text
                </Option>
                <Option
                  key="image"
                  value="image"
                  className={`hover:bg-blue-300 focus:bg-blue-300`}
                >
                  Image
                </Option>
                <Option
                  key="video"
                  value="video"
                  className={`hover:bg-blue-300 focus:bg-blue-300`}
                >
                  Video
                </Option>
                <Option
                  key="audio"
                  value="audio"
                  className={`hover:bg-blue-300 focus:bg-blue-300`}
                >
                  Audio
                </Option>
              </Select>
              {item?.messageType !== "text" ? (
                <Input
                  type="file"
                  variant="standard"
                  color="blue"
                  label="Auto Reply Media"
                  labelProps={{
                    className: "text-slate-800",
                  }}
                  value={autoRepliesMessageBodyText}
                  autoComplete="off"
                  onChange={(e) =>
                    handleInputOnChange(index, e.target.files[0], "mediaValue")
                  }
                />
              ) : (
                <Input
                  variant="standard"
                  color="blue"
                  label="Auto Reply Message"
                  labelProps={{
                    className: "text-slate-800",
                  }}
                  autoComplete="off"
                  value={autoRepliesMessageBodyText}
                  onChange={(e) =>
                    handleInputOnChange(index, e.target.value, "bodyText")
                  }
                  className="my-input-box-1"
                />
              )}
            </div>
          ))}
          <div className="w-full space-y-3">
            <div className="space-y-2">
              <Select
                variant="standard"
                color="blue"
                required
                label="Trigger Conditions"
                value={triggerCondition}
                onChange={handleTriggerConditionChange}
                className="my-input-box-1"
              >
                <Option value="">~ Choose a Trigger Condition ~</Option>
                <Option
                  className={`hover:bg-blue-300 focus:bg-blue-300`}
                  value="buttonreply"
                  key="buttonreply"
                >
                  Button Reply
                </Option>
                <Option
                  className={`hover:bg-blue-300 focus:bg-blue-300`}
                  value="exacttext"
                  key="exacttext"
                >
                  Exact Text
                </Option>
                <Option
                  className={`hover:bg-blue-300 focus:bg-blue-300`}
                  value="containstext"
                  key="containstext"
                >
                  Contains Text
                </Option>
                {/* <Option
                  className={`hover:bg-blue-300 focus:bg-blue-300`}
                  value="timepassed"
                  key="timepassed"
                >
                  Time Passed
                </Option>
                <Option
                  className={`hover:bg-blue-300 focus:bg-blue-300`}
                  value="timetocome"
                  key="timetocome"
                >
                  Time to Come
                </Option>
                <Option
                  className={`hover:bg-blue-300 focus:bg-blue-300`}
                  value="datetimeinbetween"
                  key="datetimeinbetween"
                >
                  Date Time in Between
                </Option>
                <Option
                  className={`hover:bg-blue-300 focus:bg-blue-300`}
                  value="daytimeinbetween"
                  key="daytimeinbetween"
                >
                  Day Time in Between
                </Option> */}
              </Select>
              <div className="mt-3 mb-2">{renderInputs()}</div>
            </div>
            {/* <Input
              variant="standard"
              color="blue"
              label="Delay"
              labelProps={{
                className: "text-slate-800",
              }}
              autoComplete="off"
              value={autoRepliesDelay}
              onChange={(e) => setAutoRepliesDelay(e.target.value)}
              className="my-input-box-1"
            /> */}
          </div>
          <Button variant="gradient" color="blue" type="submit">
            Create Auto Reply
          </Button>
        </form>
      </Drawer>
    </>
  );
};
const replyTableStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      backgroundColor: colorsConfig.mediumGrey,
      color: "#FFFFFF",
      fontSize: "0.9rem",
    },
  },
  cells: {
    style: {},
  },
};
export default ManageAutoReplies;
