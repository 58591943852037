import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import {
  Dialog,
  DialogBody,
  DialogHeader,
  DialogFooter,
  Input,
  Button,
} from "@material-tailwind/react";
import { toast } from "react-toastify";

import { usePaymentsContext } from "../../contexts/PaymentsContextProvider";
import { paymentConfig } from "../../config/paymentConfig.js";
import { colorsConfig } from "../../config/colorsConfig.js";

const RechargeModule = () => {
  const { rechargeViaPhonePe, getCoinsDetails, getCoinsRechargeTier } =
    usePaymentsContext();

  const [rechargeAmount, setRechargeAmount] = useState(900);
  const [gstAmount, setGstAmount] = useState(0);
  const [totalCoinsGained, setTotalCoinsGained] = useState(0);
  const [balanceCoins, setBalanceCoins] = useState(0);
  const [coinsRechargeTier, setCoinsRechargeTier] = useState([]);
  const [isOpenRechargeDialog, setIsOpenRechargeDialog] = useState(false);
  const [currentConvRate, setCurrentConvRate] = useState();

  const rechargeViaPhonePeOnClick = async () => {
    const res = await rechargeViaPhonePe(
      parseFloat(rechargeAmount) + parseFloat(gstAmount),
      "recharge"
    );

    if (res?.Error) {
      toast.error(res.Error);
    } else if (res?.paymentLink) {
      window.open(res.paymentLink, "_blank");
      setRechargeAmount(0);
    } else {
      toast.error(
        "Failed to process the payment request. Please contact WaBM support team."
      );
    }
  };

  const calculateRechargeAmount = async () => {
    if (rechargeAmount < parseFloat(paymentConfig.minRechargeAmount)) {
      toast.error(
        `Minimum recharge amount is ${parseFloat(
          paymentConfig.minRechargeAmount
        )}`
      );
    } else {
      const gstAmount = (
        (rechargeAmount * parseFloat(paymentConfig.rechargeGST)) /
        100
      ).toFixed(2);
      setGstAmount(gstAmount);
      const totalCoinsGained = (rechargeAmount * 100).toFixed(0);
      setTotalCoinsGained(totalCoinsGained);

      const filterData = coinsRechargeTier.find(
        (x) =>
          parseFloat(x.min_recharge_amount) <= parseFloat(rechargeAmount) &&
          parseFloat(x.max_recharge_amount) >= parseFloat(rechargeAmount)
      );

      setCurrentConvRate(filterData);
      setIsOpenRechargeDialog(true);
    }
  };

  const rechargeTierTableColumn = [
    {
      name: "Min Recharge Amount",
      selector: (row) => row?.min_recharge_amount,
      sortable: true,
      width: "12rem",
    },
    {
      name: "Max Recharge Amount",
      selector: (row) => row?.max_recharge_amount,
      sortable: true,
      width: "12rem",
    },
    {
      name: "Outgoing Conversation Rate",
      selector: (row) => row?.conv_rate,
      sortable: true,
      width: "13rem",
    },
    {
      name: "Incoming Conversation Rate",
      selector: (row) => row?.incomming_conv_rate,
      sortable: true,
      width: "13rem",
    },
    {
      name: "Description",
      selector: (row) => row?.description,
      sortable: true,
      grow: 3,
    },
  ];

  const fetchCoinsDetails = async () => {
    const coinsDetails = await getCoinsDetails();
    if (coinsDetails && coinsDetails.result) {
      setBalanceCoins(coinsDetails.result[0]?.balanceCoins);
    }
  };

  const fetchCoinsRechargeTier = async () => {
    const coinsRechargeTier = await getCoinsRechargeTier();
    if (coinsRechargeTier && coinsRechargeTier.result) {
      setCoinsRechargeTier(coinsRechargeTier.result);
    }
  };

  useEffect(() => {
    fetchCoinsDetails();
    fetchCoinsRechargeTier();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <main className="w-full mt-5 mb-10 lg:my-1 space-y-5">
        <div className="w-full space-y-2">
          <h1 className="text-2xl">Manage Recharge</h1>
          <h3>These are details about all your current and past recharge.</h3>
        </div>
        <div className="wbm-card-2 mt-8 space-y-5">
          <div className="flex gap-3">
            <h3 className="font-Montserrat text-lg">Balance Coins</h3>
            <span>|</span>
            <p className="text-amber-600 font-Montserrat text-lg">
              {balanceCoins ? balanceCoins : 0} coins
            </p>
          </div>

          <div className="lg:w-1/3 space-y-3">
            <h3 className="font-Montserrat text-lg">Recharge Now</h3>
            <Input
              variant="outlined"
              label="Enter your Recharge Amount"
              color="blue"
              required
              placeholder="Enter recharge amount (min. ₹900)"
              value={rechargeAmount}
              onChange={(e) => setRechargeAmount(e.target.value)}
              className="my-card-style-1 bg-white"
            />
            <Button
              type="button"
              variant="gradient"
              color="blue"
              onClick={calculateRechargeAmount}
            >
              Calculate Amount
            </Button>
          </div>

          <div className="my-card-style-1 mt-5 space-y-3">
            <h3 className="font-Montserrat text-lg">Recharge Tier</h3>
            <DataTable
              data={coinsRechargeTier}
              columns={rechargeTierTableColumn}
              style={rechargeTierTableStyles}
            />
          </div>
        </div>
      </main>
      <Dialog
        open={isOpenRechargeDialog}
        handler={() => setIsOpenRechargeDialog(!isOpenRechargeDialog)}
      >
        <DialogHeader>
          <h2 className="wabm-font-style-2">Recharge Details</h2>
        </DialogHeader>
        <DialogBody>
          <div className="flex flex-col justify-start gap-2">
            <span className="grid grid-cols-2 gap-3">
              <h3 className="border-r-[0.5px] border-slate-400">
                Recharge Amount
              </h3>
              <p>₹{rechargeAmount}</p>
            </span>
            <span className="grid grid-cols-2 gap-3">
              <h3 className="border-r-[0.5px] border-slate-400">GST Amount</h3>
              <p>
                ₹{gstAmount}{" "}
                <span className="ml-2 text-blue-700">
                  ({paymentConfig.rechargeGST}%)
                </span>
              </p>
            </span>
            <span className="grid grid-cols-2 gap-3">
              <h3 className="border-r-[0.5px] border-slate-400">
                Total Recharge Amount
              </h3>
              <p>₹{parseFloat(rechargeAmount) + parseFloat(gstAmount)}</p>
            </span>
            <span className="grid grid-cols-2 gap-3">
              <h3 className="border-r-[0.5px] border-slate-400">
                Total coins gained
              </h3>
              <p>{totalCoinsGained} coins</p>
            </span>
            <span className="grid grid-cols-2 gap-3">
              <h3 className="border-r-[0.5px] border-slate-400">
                Current Coins Deduction Tier
              </h3>
              <span className="text-blue-700">
                -{currentConvRate?.description}
              </span>
            </span>
            <span className="mt-2 text-orange-600 text-sm">
              💡 Tip : Recharge higher amount to get less coin deduction per
              conversation
            </span>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            type="button"
            variant="gradient"
            color="green"
            onClick={rechargeViaPhonePeOnClick}
            className="mx-auto"
          >
            Recharge
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};
export default RechargeModule;

const rechargeTierTableStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      backgroundColor: colorsConfig.mediumGrey,
      color: "#FFFFFF",
      fontSize: "0.9rem",
    },
  },
  cells: {
    style: {},
  },
};
